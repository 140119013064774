import React from "react"
import SEO from "../../components/seo"
import styled from "styled-components"
import FinaliseData from "../../components/finalisedata"

const StyledContainer = styled.div`
  width: 90%;
  display: flex;
  justify-content: center;
  margin: 2rem auto;
`

export default function CompletedPage() {
  return (
    <>
      <SEO title="Payment Completed" />
      <StyledContainer>
        <FinaliseData />
      </StyledContainer>
    </>
  )
}
