import React from "react"
import { Link } from "gatsby"
import Card from "react-bootstrap/Card"
import Button from "react-bootstrap/Button"
import Container from "react-bootstrap/Container"


export default function FinaliseData() {
  
  
return (
 <>
 
  <Container className="d-flex-column justify-content-center align-items-center">
    <Card className="w-100" style={{maxWidth: `50rem`, margin: `0 auto`}}>
    <Card.Header className="m-3 p-3 text-center" style={{textShadow: `0.25rem 0.25rem #412456`}}>

    <h3>Payment Completed</h3>
    </Card.Header>
    <Card.Body className="m-3 text-center">
    <p>Thank you for booking a reading.</p>  
    <br />
    <p>A tax invoice will be arriving in your inbox momentarily.</p>
    <br />
    <p>Expect your reading to be delivered to the same inbox within 48 hours.</p>
    
     
    </Card.Body>
  
  
    

  <Card.Footer className="m-0 p-0">
  <Button className="w-100" type="button" >
  <Link to="/">Continue Browsing</Link>

  </Button>

  </Card.Footer>

    </Card>

   
  </Container>
 
 </>

)
}



